import React from 'react'
import { Builder, withChildren } from '@builder.io/react'
import Slider from '../../../shared/before-after-carousel/slider'

const BeforeAfterSlider = ({ children }) => {
  return <Slider>{children}</Slider>
}

Builder.registerComponent(withChildren(BeforeAfterSlider), {
  name: 'Before After Slider',
  canHaveChildren: true
})
