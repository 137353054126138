import React from 'react'
import { Builder } from '@builder.io/react'
import ProvenButtonWithIntent from 'components/shared/proven-button-with-intent'
import { builderConfig } from './Button'
import { useBuilderSharedData } from 'hooks/useBuilderSharedData'

const ButtonWithIntent = ({ text: textFromBuilder, color, variant, intent, fullWidth, gift }) => {
  const { reactOnly } = useBuilderSharedData()
  const text = textFromBuilder || reactOnly?.pdp?.defaultUnauthorizedButtonText

  return (
    <ProvenButtonWithIntent
      text={text}
      color={color}
      variant={variant}
      intent={intent}
      fullWidth={fullWidth}
      gift={gift}
    />
  )
}

Builder.registerComponent(ButtonWithIntent, {
  ...builderConfig,
  name: 'Button With Intent',
  inputs: [
    ...builderConfig.inputs.filter(({ name }) => name !== 'color'),
    { name: 'gift', type: 'boolean', defaultValue: false },
    { name: 'intent', type: 'text', enum: ['skin', 'eye'] },
    {
      name: 'color',
      type: 'text',
      enum: ['primary', 'secondary', 'inherit'],
      defaultValue: 'secondary'
    }
  ]
})
