import {
  SUCCESS_SUBSCRIBE_PRODUCT,
  FAIL_SUBSCRIBE_PRODUCT,
  SET_COUPON,
  GET_ORDER_DETAILS_SUCCESS,
  SET_STATUS,
  START_LOAD_STATES,
  SUCCESS_LOAD_STATES,
  OPEN_CHECKOUT_SLIDE,
  CLOSE_CHECKOUT_SLIDE,
  OPEN_LOGIN_SLIDE,
  CLOSE_LOGIN_SLIDE,
  SET_CHECKOUT_STEP,
  SET_HAS_SENT_ORDER_COMPLETED,
  SET_CJEVENT,
  SET_MOCK_ORDER_ID,
  SET_MOCK_CART_ID,
  SET_MOCK_CHECKOUT_ID,
  CART_OPEN,
  CART_CLOSE,
  OPEN_ORDER_TOTAL,
  CLOSE_ORDER_TOTAL,
  SET_SKIP_ACCESSORIES,
  SET_ACCESSORIES_PURCHASE_PREVIEW,
  CLOSE_ORDER_REQUEST_IN_PROGRESS,
  CLOSE_ORDER_REQUEST_SUCCESS,
  CLOSE_ORDER_REQUEST_ERROR
} from '../constants/actionTypes'
import provenApi from '../services/proven-api'
import provenPay from 'services/proven-pay'
import { updateAccountInfo } from '../actions/account.actions'
import { closeOrderUrl, productsSubscribeUrl } from '../constants/endpoints'
//import { clearCart } from "./cart.actions";

export const closeOrder = itemsToAdd => async dispatch => {
  try {
    dispatch({ type: CLOSE_ORDER_REQUEST_IN_PROGRESS })
    const { data } = await provenPay.put(closeOrderUrl, { itemsToAdd })
    dispatch({ type: CLOSE_ORDER_REQUEST_SUCCESS })
    return data
  } catch (error) {
    dispatch({ type: CLOSE_ORDER_REQUEST_ERROR, error })
  }
}

// TODO deprecate this
export const subscribeProducts = (plans, coupon, mockCheckoutId) => (dispatch, getState) => {
  const { giftCard, skipTheLineValue } = getState().checkout
  const payload = {
    plans,
    ...(coupon ? { coupon } : {}),
    ...(giftCard ? { giftCard } : {}),
    ...(!skipTheLineValue
      ? {}
      : {
          invoiceItems: [
            {
              id: 'skip-the-line',
              qty: 1
            }
          ]
        }),
    checkoutId: mockCheckoutId ? mockCheckoutId : undefined
  }

  return provenApi.post(productsSubscribeUrl, payload).then(
    ({ data }) => {
      // ddango: we should not clear cart as soon as we subscribe. This triggers a clearing of
      // products which we need to use to display the thank you page.

      // dispatch(clearCart());
      dispatch({ type: SUCCESS_SUBSCRIBE_PRODUCT })
      return data
    },
    err => {
      dispatch({ type: FAIL_SUBSCRIBE_PRODUCT })
      throw err
    }
  )
}

export const loadStates = () => async dispatch => {
  try {
    dispatch({ type: START_LOAD_STATES })
    const { data } = await provenApi.get('address/states?search=')
    dispatch({ type: SUCCESS_LOAD_STATES, states: data.result })
  } catch (error) {
    // console.log(error);
  }
}

export const setCoupon = coupon => dispatch => {
  return new Promise(resolve => {
    dispatch({
      type: SET_COUPON,
      coupon
    })

    resolve()
  })
}

export const setCJEvent = cjevent => dispatch => {
  return new Promise(resolve => {
    dispatch({
      type: SET_CJEVENT,
      cjevent
    })

    resolve()
  })
}

export const setMockCartId = mockCartId => dispatch => {
  return new Promise(resolve => {
    dispatch({
      type: SET_MOCK_CART_ID,
      mockCartId
    })

    resolve()
  })
}

export const setMockOrderId = mockOrderId => dispatch => {
  return new Promise(resolve => {
    dispatch({
      type: SET_MOCK_ORDER_ID,
      mockOrderId
    })

    resolve()
  })
}

export const setMockCheckoutId = mockCheckoutId => dispatch => {
  return new Promise(resolve => {
    dispatch({
      type: SET_MOCK_CHECKOUT_ID,
      mockCheckoutId
    })

    resolve()
  })
}

export const getOrderDetailsAction = orderDetails => ({
  type: GET_ORDER_DETAILS_SUCCESS,
  orderDetails
})

export const setAccessoriesPurchasePreview = previewDetails => ({
  type: SET_ACCESSORIES_PURCHASE_PREVIEW,
  previewDetails
})

export const setStatus = status => dispatch => {
  dispatch({ type: SET_STATUS, status })
}

export const toggleCheckoutSlide = (state /*pathname*/) => dispatch => {
  if (state) {
    dispatch({ type: OPEN_CHECKOUT_SLIDE })
  } else {
    dispatch({ type: CLOSE_CHECKOUT_SLIDE })
  }
}

export const toggleCheckoutPopup = (state /*pathname*/) => dispatch => {
  if (state) {
    dispatch({ type: CART_OPEN })
  } else {
    dispatch({ type: CART_CLOSE })
  }
}

export const toggleLoginSlide = (openLoginParams, prepopulatedEmail) => dispatch => {
  /**
   * @openLoginParams can be BOOLEAN or an OBJECT with the following properties:
   * - view: type of number (0 - magic link, 1 - forgot password; 2 - email password)
   * - navBtnTxt: type of text (alternative name for the 'Close' button)
   * - closeFunc: type of function (to be called on the 'Close' button click)
   * - togglingEmailPassword: boolean, true if toggling EmailPassword (not Magic Link)
   */
  if (openLoginParams) {
    dispatch({ type: OPEN_LOGIN_SLIDE, prepopulatedEmail, openLoginParams })
  } else {
    dispatch({ type: CLOSE_LOGIN_SLIDE })
  }
}

export const closeLoginSlide = () => dispatch => {
  dispatch({ type: CLOSE_LOGIN_SLIDE })
  dispatch(updateAccountInfo({ isMagicLinkSent: false }))
}

export const setCheckoutStep = step => dispatch => {
  dispatch({ type: SET_CHECKOUT_STEP, step })
}

export const setHasSentOrderCompleted = hasSentOrderCompleted => dispatch => {
  dispatch({ type: SET_HAS_SENT_ORDER_COMPLETED, hasSentOrderCompleted })
}

export const openOrderTotal = () => dispatch => {
  dispatch({ type: OPEN_ORDER_TOTAL })
}

export const closeOrderTotal = () => dispatch => {
  dispatch({ type: CLOSE_ORDER_TOTAL })
}
export const setSkipAccessories = value => dispatch => {
  dispatch({ type: SET_SKIP_ACCESSORIES, value })
}
