import { Builder } from '@builder.io/react'
import {
  CLEANSER_PRODUCT,
  DAY_MOISTURIZER_PRODUCT,
  NIGHT_CREAM_PRODUCT,
  ONE_TIME_PRODUCT_IDS,
  SYSTEM_PRODUCT
} from '../../../../constants/products'
import ProvenResponsiveContainer from '../../../shared/proven-responsive-container'
import React from 'react'
import LPProductHeroBasedOnUserAuthentication from '../../../shared/lp-product-hero'

export const LPProductHeroForBuilder = props => {
  return (
    <ProvenResponsiveContainer>
      <LPProductHeroBasedOnUserAuthentication {...props} />
    </ProvenResponsiveContainer>
  )
}

Builder.registerComponent(LPProductHeroForBuilder, {
  name: 'LP Product Hero',
  inputs: [
    {
      name: 'product',
      type: 'text',
      enum: [
        SYSTEM_PRODUCT,
        CLEANSER_PRODUCT,
        DAY_MOISTURIZER_PRODUCT,
        NIGHT_CREAM_PRODUCT,
        ...ONE_TIME_PRODUCT_IDS
      ]
    },
    { name: 'title', type: 'text' },
    { name: 'subtitle', type: 'text' },
    { name: 'description', type: 'text' },
    { name: 'extraNote', type: 'text' },
    {
      name: 'features',
      type: 'list',
      defaultValue: [],
      subFields: [
        {
          name: 'title',
          type: 'text'
        },
        {
          name: 'paragraph',
          type: 'html'
        }
      ]
    },
    { name: 'size', type: 'text' },
    {
      name: 'carouselImages',
      defaultValue: [],
      type: 'list',
      subFields: [
        {
          name: 'image',
          type: 'file'
        }
      ]
    },
    {
      name: 'hideTargetingSection',
      type: 'boolean',
      defaultValue: false,
      helperText: `Hide the targeting section`
    },
    {
      name: 'hideIngredientsSection',
      type: 'boolean',
      defaultValue: false,
      helperText: `Hide the ingredients section`
    }
  ]
})
