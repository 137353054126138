/* eslint-disable no-unused-vars */
import { UPDATE_SHOP_SUBSCRIPTION, SET_ACCESSORIES } from '../constants/actionTypes'
import { shopSubscriptionUrl } from '../constants/endpoints'
import provenPay from '../services/proven-pay'
import { EYE_CREAM_CAMPAIGNS } from '../constants/configs/campaigns'
import { setCoupon } from './checkout.actions'
import { EYE_CREAM_PRODUCT } from '../constants/products'

const getShopSubscription = async () => {
  try {
    const { data } = await provenPay.get(shopSubscriptionUrl)
    return data
  } catch (err) {
    throw new Error(err)
  }
}

export const loadShopSubscription = () => async dispatch => {
  const shopSubscription = await getShopSubscription()
  dispatch({ type: UPDATE_SHOP_SUBSCRIPTION, shopSubscription })
  return shopSubscription
}
const autoApplyCoupon = (productId, oneTime, dispatch) => {
  if (productId === EYE_CREAM_PRODUCT) {
    const isSwitchingToSubscription = oneTime
    const now = Date.now()
    const automateApplyCouponCode = Object.keys(EYE_CREAM_CAMPAIGNS).find(couponCode => {
      const startDate = new Date(EYE_CREAM_CAMPAIGNS[couponCode].startDate)
      const endDate = new Date(EYE_CREAM_CAMPAIGNS[couponCode].endDate)
      return startDate.getTime() < now && now < endDate.getTime()
    })
    const shouldAutomaticApplyCoupon = Boolean(automateApplyCouponCode)

    if (shouldAutomaticApplyCoupon && isSwitchingToSubscription) {
      dispatch(setCoupon(automateApplyCouponCode))
    } else {
      dispatch(setCoupon())
    }
  }
}
