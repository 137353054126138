import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DropdownButton from '../../../../../shared/dropdown-button'
import { trackEvent } from '../../../../../../utils/analytics'
import propTypes from 'prop-types'
//import { useComingSoonCountryCheck } from '../../../../../../hooks/useCountrySupported'
import { Typography, Box, Stack } from '@mui/material'
import ProvenIcon from '../../../../../shared/proven-icon'
import ProvenIconWithRotation from '../../../../../shared/proven-icon-with-rotation'
import { setLogoutUserAttempt, signoutUser } from '../../../../../../actions/auth.actions'
import { useLocation } from 'react-router-dom'
import { useCartActions } from 'services/shopify/hooks'

function UserDropdown(props) {
  const cartActions = useCartActions()
  const [open, setOpen] = useState(false)
  const firstName = useSelector(state => state.account.info.firstName)
  const dispatch = useDispatch()
  const location = useLocation()
  const isThankYouPage = location?.pathname && location.pathname.indexOf('thank-you') > -1

  const handleTrackClick = text => {
    trackEvent('header_cta', {
      CTA: text,
      dashboard: props.dashboard,
      location: 'user menu'
    })
  }

  const handleLogout = () => {
    cartActions.clear()
    if (isThankYouPage) {
      dispatch(setLogoutUserAttempt())
    } else {
      dispatch(signoutUser())
    }
    handleTrackClick('Log Out')
  }

  return (
    <Box sx={{ pr: 0.5 }}>
      <DropdownButton
        variant="dashboard"
        dropdownAtLeftSide={true}
        onOpen={() => {
          setOpen(true)
        }}
        onClose={() => {
          setOpen(false)
        }}
        customButtom={
          <Stack
            sx={{ cursor: 'pointer', height: '42px' }}
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            gap={2}
            data-cy="user-dropdown"
          >
            <ProvenIcon name="account" type="tab" color="gray.white" />
            <Typography variant="footnoteBold" color="gray.white">
              {firstName}
            </Typography>
            <Box
              sx={{
                transform: `translateX(13px)`,
                alignSelf: 'center',
                height: '18px',
                ml: 'auto'
              }}
            >
              <ProvenIconWithRotation
                name="arrow-down"
                type="system"
                isRotated={open}
                color="gray.middleGray"
                iconSize="0.875rem"
              />
            </Box>
          </Stack>
        }
      >
        <Typography
          onClick={handleLogout}
          sx={{
            px: 0.5,
            py: 1.75,
            minWidth: '220px',
            cursor: 'pointer',
            '&:hover': {
              color: 'primary.main'
            }
          }}
          variant="body2"
          color="gray.gray"
        >
          Logout
        </Typography>
      </DropdownButton>
    </Box>
  )
}

UserDropdown.defaultProps = {
  dashboard: undefined
}

UserDropdown.propTypes = {
  dashboard: propTypes.string
}

export default UserDropdown
