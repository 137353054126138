import { hot } from 'react-hot-loader'
import React, { useEffect } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import Router from './router'
import './app.scss'
import './animations.scss'
import './fonts.css'
import 'react-sweet-progress/lib/style.css'
import { colors } from 'styles'
import ProvenMobileMenu from './shared/proven-header/mobile-menu'
import LoginPanel from './shared/login-panel'
import { clearAccountError } from '../actions/account.actions'
import { closeLoginSlide } from '../actions/checkout.actions'
import { clearAuth, clearPasswordlessAuth } from '../actions/auth.actions'
import { setIsWelcomeBackPage } from '../actions/app.actions'
import { CHARGEBEE_KEY, CHARGEBEE_SITE } from '../constants/configs/payment'
import PebblePost from './partners/pebble-post'
import ProvenSnackbarProvider from '../providers/ProvenSnackbarProvider'
import AuthProvider from '../providers/AuthProvider'
import NotificationSnackbarWrapper from './shared/snackbar/notification-snackbar-wrapper'
import { useForAnalytics } from '../hooks/useForAnalytics'
import { IntentAndFunnelsProvider } from '../providers/IntentAndFunnelsProvider'
import '../services/builder'
import useForAppEdition from '../hooks/useForAppEdition'
import useQuery from 'hooks/use-query'
import { YotpoProvider } from '../providers/YotpoProvider'
import useForLeadEmailFromUTM from '../hooks/useForLeadEmailFromUTM'
import useForLogoutUserWhenInvalidAccount from '../hooks/useForLogoutUserWhenInvalidAccount'
import { CartProvider, ShopifyProvider } from '@shopify/hydrogen-react'
import { PUBLIC_STORE_DOMAIN, PUBLIC_STOREFRONT_API_TOKEN } from 'constants/constants'
import { CART_FRAGMENT } from '../services/shopify/fragments'
import { openCart } from '../actions/cart.actions'
import { Suspense } from 'react'
import Loading from './shared/loading'
import AnalyticsPageViewsProvider from 'providers/AnalyticsPageViewsProvider'
//import Podsights from "./partners/podsights";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      suspense: true
    }
  }
})
const theme = createTheme({
  breakpoints: {
    values: {
      // we define the breakpoints to make branding-R1 keep the old breakpoints, since muiV5 changes the default breakpoints from v4
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  palette: {
    third: {
      light: '#0e0e0e',
      main: '#151515',
      contrastText: '#f44336'
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: 'Brandon Grotesque, serif'
  },
  props: {
    MuiFilledInput: {
      disableUnderline: true
    },
    MuiWithWidth: {
      initialWidth: 'md'
    }
  },
  overrides: {
    MuiTab: {
      root: {
        fontSize: 16
      },
      textColorPrimary: {
        color: colors['color-black']
      }
    },
    MuiSnackbar: {
      root: {
        textAlign: 'center',
        fontWeight: 'bold',
        marginTop: '55px',
        zIndex: 1,
        boxShadow: 'none'
      }
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: '#F9F6EE',
        color: '#e39e2e',
        boxShadow: 'none'
      },
      message: {
        margin: '0 auto',
        textTransform: 'uppercase'
      }
    },
    MuiLink: {
      root: {
        cursor: 'pointer'
      }
    },
    MuiFilledInput: {
      root: {
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor: 'white'
        },
        '&$focused': {
          backgroundColor: 'white'
        }
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 14
      }
    }
  }
})

const App = () => {
  const query = useQuery()

  const dispatch = useDispatch()
  useForAnalytics()
  useForAppEdition()
  useForLeadEmailFromUTM()

  useEffect(() => {
    window.Chargebee.init({
      site: CHARGEBEE_SITE,
      publishableKey: CHARGEBEE_KEY
    })
    dispatch(clearAccountError())
    dispatch(clearAuth())
    if (!query.get('openLoginSlide')) {
      dispatch(clearPasswordlessAuth())
    }
    dispatch(closeLoginSlide())
    dispatch(setIsWelcomeBackPage(false))
  }, [])

  return (
    <Suspense fallback={<Loading />}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <ProvenSnackbarProvider>
              <NotificationSnackbarWrapper>
                <IntentAndFunnelsProvider>
                  <YotpoProvider>
                    <ShopifyProvider
                      storeDomain={PUBLIC_STORE_DOMAIN}
                      storefrontToken={PUBLIC_STOREFRONT_API_TOKEN}
                      storefrontApiVersion="2024-04"
                      countryIsoCode="US"
                      languageIsoCode="EN"
                    >
                      <CartProvider
                        cartFragment={CART_FRAGMENT}
                        onLineAdd={() => {
                          dispatch(openCart())
                        }}
                        onCreateComplete={() => {
                          dispatch(openCart())
                        }}
                      >
                        <AnalyticsPageViewsProvider>
                          <Router />
                          <ProvenMobileMenu />
                          <LoginPanel />
                        </AnalyticsPageViewsProvider>
                      </CartProvider>
                    </ShopifyProvider>
                  </YotpoProvider>
                </IntentAndFunnelsProvider>
              </NotificationSnackbarWrapper>
            </ProvenSnackbarProvider>
            <PebblePost />
            {/*<Podsights />*/}
          </MuiThemeProvider>
        </AuthProvider>
      </QueryClientProvider>
    </Suspense>
  )
}

// eslint-disable-next-line no-undef
export default hot(module)(App)
